/**
 * @module sitn.mistradistance.module
 */
import olFormatGeoJSON from 'ol/format/GeoJSON.js';
import olSourceVector from 'ol/source/Vector.js';
import olLayerVector from 'ol/layer/Vector.js';
import ngeoToolActivate from 'ngeo/misc/ToolActivate.js';
import ngeoToolActivateMgr from 'ngeo/misc/ToolActivateMgr.js';
import * as olEvents from 'ol/events.js';
import {unByKey} from 'ol/Observable.js';
import olStyleCircle from 'ol/style/Circle.js';
import olStyleStyle from 'ol/style/Style.js';
import olStyleFill from 'ol/style/Fill.js';
import olStyleStroke from 'ol/style/Stroke.js';
import angular from 'angular';

const exports = angular.module('mistradistance', [ngeoToolActivateMgr.name]);

exports.Component = {
  controller: 'sitnMistradistanceController',
  bindings: {
    'map': '<', // one way Angular binding !
    'active': '=',
    'layers': '<',
  },
  templateUrl: () => 'sitn/mistradistance',
};

exports.run(
  /* @ngInject */ ($templateCache) => {
    $templateCache.put('sitn/mistradistance', require('./mistradistance.html'));
  }
);

exports.component('sitnMistradistance', exports.Component);

/**
 * @param {!angular.Scope} $scope Angular scope.
 * @param {!angular.$http} $http Angular http service.
 * @param {ngeo.misc.ToolActivateMgr} ngeoToolActivateMgr Ngeo ToolActivate manager
 *     service.
 * @param {string} sitnMistraDistanceUrl URL of the SITN mistra service.
 * @constructor
 * @private
 * @ngInject
 */
exports.Controller = function ($scope, $http, ngeoToolActivateMgr, sitnMistraDistanceUrl) {
  /**
   * @type {angular.$http}
   * @private
   */
  this.$http_ = $http;

  /**
   * @type {Array<string>}
   */
  this.layers;

  /**
   * @type {boolean}
   * @export
   */
  this.hidetext = true;

  /**
   * @type {boolean}
   * @export
   */
  this.hidetext2 = false;

  /**
   * @type {boolean}
   * @export
   */
  this.showcog = false;

  /**
   * @type {boolean}
   * @export
   */
  this.showresults = false;

  /**
   * @type {boolean}
   * @export
   */
  this.showresults2 = false;

  /**
   * @type {boolean}
   * @export
   */
  this.measureLength_active = true;

  /**
   * @type {boolean}
   * @export
   */
  this.measureArea_active = false;

  /**
   * @type {Array<float>}
   */
  this.first_point = [];

  /**
   * @type {float}
   */
  this.intersection_length = 0;

  /**
   * @type {float}
   */
  this.intersection_area = 0;

  /**
   * @type {integer}
   * @export
   */
  this.requested_point = 0;

  /**
   * @type {ol.Map}
   * @export
   */
  this.map;
  /**
   * @type {boolean}
   */
  this.active;
  /**
   * @type {Object}
   */
  this.data = {};

  /**
   * @type {Object}
   */
  this.axis = {};

  /**
   * @type {string}
   * @private
   */
  this.sitnMistraDistanceUrl_ = sitnMistraDistanceUrl;

  /**
   * @type {ngeo.misc.ToolActivateMgr}
   * @private
   */
  this.ngeoToolActivateMgr_ = ngeoToolActivateMgr;

  /**
   * @type {ol.source.Vector}
   */
  this.source = new olSourceVector();

  /**
   * @type {ol.layer.Vector}
   */
  this.vectorLayer;

  /**
   * @type {ol.EventsKey}
   * @private
   */
  this.mistraClickListenerKey_;

  // Initialize the tools inside of the tool manager
  this.tool = new ngeoToolActivate(this, 'active');
  this.ngeoToolActivateMgr_.registerTool('mapTools', this.tool, false);

  $scope.$watch(
    // First arg = value to watch
    function () {
      return this.active;
    }.bind(this),
    function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateEventsListening_(newValue);
      }
    }.bind(this)
  );
};

exports.Controller.prototype.$onInit = function () {
  var styleFunction = (feature) => {
    const geom_type = feature.getGeometry().getType();

    if (geom_type == 'Point') {
      return new olStyleStyle({
        image: new olStyleCircle({
          radius: 8,
          fill: new olStyleFill({
            color: [0, 255, 0, 0.4],
          }),
          stroke: new olStyleStroke({
            color: [0, 255, 0, 0.6],
            width: 1,
          }),
        }),
      });
    } else if (geom_type == 'LineString') {
      return new olStyleStyle({
        stroke: new olStyleStroke({
          color: [0, 255, 0, 0.6],
          width: 5,
        }),
      });
    } else if (geom_type == 'Polygon') {
      return new olStyleStyle({
        stroke: new olStyleStroke({
          color: [200, 0, 0, 0.6],
          width: 2,
        }),
        fill: new olStyleFill({
          color: [200, 0, 0, 0.3],
        }),
      });
    }
  };

  this.vectorLayer = new olLayerVector({
    source: this.source,
    style: styleFunction,
  });
  this.vectorLayer.setMap(this.map);
};
/**
 * @param {boolean} activate Activation state of the plugin
 * @private
 */
exports.Controller.prototype.updateEventsListening_ = function (activate) {
  if (activate === true) {
    this.ngeoToolActivateMgr_.activateTool(this.tool);
    this.mistraClickListenerKey_ = olEvents.listen(this.map, 'click', this.click, this);
  } else {
    this.ngeoToolActivateMgr_.deactivateTool(this.tool);
    unByKey(this.mistraClickListenerKey_);
    this.resetVariables();
    this.hidetext = true;
    this.hidetext2 = false;
    this.showcog = false;
  }
};

/**
 * click action should trigger a HTTP request on coordinates and open a popup.
 * @param {ol.MapBrowserPointerEvent} e An ol map browser pointer event.
 * @private
 */
exports.Controller.prototype.click = function (e) {
  if (this.measureArea_active !== true || this.requested_point === 0 || this.requested_point === 2) {
    this.resetVariables();
  }

  const est = e.coordinate[0];
  const nord = e.coordinate[1];
  const point = [est, nord];
  this.showcog = true;

  let request_layers = this.layers;
  let type_ = 'linear';
  let request_axis;
  if (this.measureArea_active === true) {
    this.requested_point += 1;
    request_layers = ['rt11_axes_cantonaux'];
    type_ = 'areal';
    if (this.requested_point === 2) {
      request_axis = this.axis['axis_id'];
    }
  }

  this.$http_
    .post(this.sitnMistraDistanceUrl_, {
      data: {
        'point': point,
        'layers': request_layers,
        'type': type_,
        'requested_point': this.requested_point,
        'asg_iliid': request_axis,
        'first_point': this.first_point,
      },
    })
    .then(
      (response) => {
        this.updatePanel_(response.data);
      },
      () => {
        this.showcog = false;
        alert('Une erreur est survenue. Merci de contacter le SITN (sitn@ne.ch)');
      }
    );
};

exports.Controller.prototype.resetVariables = function () {
  this.source.clear();
  this.showresults = false;
  this.showresults2 = false;
  this.hidetext = false;
  this.hidetext2 = false;
  this.first_point = [];
  this.requested_point = 0;
};

/**
 * @param {Object} results Returned object from the webservice
 * @param {Array<number>} coordinates Coordinates of clicked point
 * @private
 */
exports.Controller.prototype.updatePanel_ = function (results) {
  if (results['distance_error']) {
    this.resetVariables();
    this.showcog = false;
    alert("Erreur\n\nVeuillez cliquer plus près d'un axe! ");
    return;
  }

  if (results['wrong_axis_correspondance']) {
    this.resetVariables();
    this.showcog = false;
    alert('Erreur\n\nVous avez cliqué sur des axes qui ne correspondent pas (pas le même axe) !');
    return;
  }

  if (results['multigeometry']) {
    this.resetVariables();
    this.showcog = false;
    alert('Erreur\n\nCe calcul ne peut pas être effectué pour cet axe !');
    return;
  }

  const type_axe = {
    'rt10_axes_nationaux': 'Axes routes nationales',
    'rt11_axes_cantonaux': 'Axes routes cantonales',
    'rt12_axes_communaux': 'Axes routes communales',
  };

  const features = new olFormatGeoJSON().readFeatures(results);

  let feature, properties;

  if (this.measureArea_active !== true || this.requested_point === 1) {
    for (var i = 0; i < features.length; i++) {
      feature = features[i];
      properties = feature.getProperties();
      if (properties['obj_type'] === 'axis') {
        this.axis = properties;
        this.data = {
          'axis_type': type_axe[properties['layer']],
          'axis_name': properties['axis_name'],
          'sector_name': properties['sector_name'],
          'sector_length': properties['sector_length'],
          'total_length': properties['total_length'],
        };
      }
      if (properties['obj_type'] !== 'end_point') {
        this.source.addFeature(feature);
      } else {
        this.first_point = feature.getGeometry().getCoordinates();
      }
    }
    this.showresults = true;
  } else if (this.measureArea_active === true && this.requested_point === 2) {
    // We want to get rid of all vector objects
    this.source.clear();
    this.intersection_length = 0;
    this.intersection_area = 0;
    for (var i = 0; i < features.length; i++) {
      feature = features[i];
      properties = feature.getProperties();
      if (properties['obj_type'] === 'axis') {
        this.data2 = {
          'axis_type': type_axe[properties['layer']],
          'axis_name': properties['axis_name'],
          'sector_name': properties['sector_name'],
          'sector_length': properties['sector_length'],
          'total_length': properties['total_length'],
        };
      }
      if (
        /*properties['obj_type'].includes("point") === true ||*/ properties['obj_type'] === 'road_polygon'
      ) {
        this.source.addFeature(feature);
      }
      if (properties['obj_type'] === 'road_polygon') {
        this.intersection_area += feature.getGeometry().getArea();
      }
      if (properties['obj_type'] === 'intersection_line') {
        this.intersection_length += feature.getGeometry().getLength();
      }
    }
    this.showresults2 = true;
    this.hidetext2 = false;
  }
  this.showcog = false;
};

/**
 * @param {String} string Type of clicked button
 */
exports.Controller.prototype.pluginSwitcher = function (type) {
  this.resetVariables();
  if (type == 'linear') {
    this.measureLength_active = true;
    this.measureArea_active = false;
    this.hidetext = true;
  } else {
    this.measureLength_active = false;
    this.measureArea_active = true;
    this.hidetext2 = true;
  }
};

exports.controller('sitnMistradistanceController', exports.Controller);

export default exports;
