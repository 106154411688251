/**
 * @module sitn.rasterstatistics.module
 */
import olFormatGeoJSON from 'ol/format/GeoJSON.js';
import olSourceVector from 'ol/source/Vector.js';
import olLayerVector from 'ol/layer/Vector.js';
import olInteractionDraw from 'ol/interaction/Draw.js';
import olGeomLineString from 'ol/geom/LineString.js';
import ngeoToolActivate from 'ngeo/misc/ToolActivate.js';
import ngeoToolActivateMgr from 'ngeo/misc/ToolActivateMgr.js';
import angular from 'angular';

const exports = angular.module('rasterstatistics', [ngeoToolActivateMgr.name]);

/**
 *
 * @return {angular.Directive} The address directive.
 */
exports.Directive = function () {
  return {
    controller: 'sitnRasterstatisticsController as ctrl',
    bindToController: true,
    scope: {
      'map': '<', // one way Angular binding !
      'active': '=',
      'layers': '<',
      'maxarea': '<',
    }, // all the map values are available (this.map is binded) (it defines how the values are transfered)
    templateUrl: 'sitn/rasterstatistics',
  };
};

exports.run(
  /* @ngInject */ ($templateCache) => {
    $templateCache.put('sitn/rasterstatistics', require('./rasterstatistics.html'));
  }
);

exports.directive('sitnRasterstatistics', exports.Directive);

/**
 * @param {!angular.Scope} $scope Angular scope.
 * @param {!angular.$http} $http Angular http service.
 * @param {ngeo.misc.ToolActivateMgr} ngeoToolActivateMgr Ngeo ToolActivate manager
 *     service.
 * @param {string} sitnRasterUrl URL of the SITN raster service.
 * @constructor
 * @private
 * @ngInject
 */
exports.Controller = function ($scope, $http, ngeoToolActivateMgr, sitnRasterUrl) {
  /**
   * @type {angular.Scope}
   * @private
   */
  this.$scope_ = $scope;

  /**
   * @type {angular.$http}
   * @private
   */
  this.$http_ = $http;

  /**
   * @type {Array<string>}
   */
  this.layers;

  /**
   * @type {number}
   */
  this.maxarea;

  /**
   * @type {boolean}
   * @export
   */
  this.hidetext = true;

  /**
   * @type {boolean}
   * @export
   */
  this.showcog = false;

  /**
   * @type {boolean}
   * @export
   */
  this.showresults = false;

  /**
   * @type {ol.Map}
   * @export
   */
  this.map;
  /**
   * @type {boolean}
   */
  this.active;
  /**
   * @type {Object}
   */
  this.data = {};

  /**
   * @type {string}
   * @private
   */
  this.sitnRasterUrl_ = sitnRasterUrl;

  /**
   * @type {ngeo.misc.ToolActivateMgr}
   * @private
   */
  this.ngeoToolActivateMgr_ = ngeoToolActivateMgr;

  /**
   * @type {ol.source.Vector}
   */
  this.source = new olSourceVector();

  /**
   * @type {ol.layer.Vector}
   */
  this.vectorLayer;

  /**
   * @type {ol.interaction.Draw}
   */
  this.interaction;

  // Initialize the tools inside of the tool manager
  this.tool = new ngeoToolActivate(this, 'active');
  this.ngeoToolActivateMgr_.registerTool('mapTools', this.tool, false);

  $scope.$watch(
    // First arg = value to watch
    function () {
      return this.active;
    }.bind(this),
    function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateEventsListening_(newValue);
      }
    }.bind(this)
  );
};

exports.Controller.prototype.$onInit = function () {
  this.vectorLayer = new olLayerVector({
    source: this.source,
  });
  this.vectorLayer.setMap(this.map);

  this.interaction = new olInteractionDraw(
    /** @type {olx.interaction.DrawOptions} */ ({
      type: 'Polygon',
      source: this.vectorLayer.getSource(),
    })
  );

  this.interaction.on('drawend', (e) => {
    this.drawEnd_(e.feature);
  });

  this.interaction.on('drawstart', () => {
    this.source.clear();
    this.showresults = false;
  });

  this.interaction.setActive(false);
  this.map.addInteraction(this.interaction);
};
/**
 * @param {boolean} activate Activation state of the plugin
 * @private
 */
exports.Controller.prototype.updateEventsListening_ = function (activate) {
  if (activate === true) {
    this.ngeoToolActivateMgr_.activateTool(this.tool);
    this.interaction.setActive(true);
  } else {
    this.ngeoToolActivateMgr_.deactivateTool(this.tool);
    this.interaction.setActive(false);
    this.source.clear();
    this.hidetext = true;
    this.showcog = false;
    this.showresults = false;
  }
};

/**
 * @param {ol.Feature} feature Openlayers feature which was drawn
 * @private
 */
exports.Controller.prototype.drawEnd_ = function (feature) {
  var geom = /** @type {ol.geom.Polygon} */ (feature.getGeometry());
  if (geom.getArea() > this.maxarea) {
    alert(
      'La surface que vous avez dessinée dépasse la limite autorisée !\n\nLa limite de surface est ' +
        this.maxarea +
        ' m2.\n\nVeuillez dessiner des surfaces plus petites.'
    );
    return;
  }

  var geosjon = new olFormatGeoJSON().writeFeatures([feature]);
  this.hidetext = false;
  this.showcog = true;
  this.$http_
    .post(this.sitnRasterUrl_, {
      'feature': geosjon,
      'layers': this.layers,
    })
    .then(
      (response) => {
        this.updatePanel_(response.data);
      },
      () => {
        this.showcog = false;
        this.hidetext = true;
        alert('Une erreur est survenue. Merci de contacter le SITN (sitn@ne.ch)');
      }
    );
};

/**
 * @param {Object} results Returned object from the webservice
 * @private
 */
exports.Controller.prototype.updatePanel_ = function (results) {
  results = results['layer_statistics'];
  this.showcog = false;
  this.showresults = true;
  var feature = this.source.getFeatures()[0];
  var geom = /** @type {ol.geom.Polygon} */ (feature.getGeometry());

  var coordinates = geom.getCoordinates();
  var line = new olGeomLineString(coordinates[0]);
  this.data = {
    'geom_area': geom.getArea(),
    'geom_perimeter': line.getLength(),
    'dtm': {
      'issampled': results['mnt']['isSampled'],
      'std': results['mnt']['results']['std'],
      'max': results['mnt']['results']['max'],
      'min': results['mnt']['results']['min'],
      'med': results['mnt']['results']['median'],
      'avg': results['mnt']['results']['mean'],
    },
    'slope': {
      'issampled': results['slope_classes']['isSampled'],
      'one': results['slope_classes']['results']['1'],
      'two': results['slope_classes']['results']['2'],
      'three': results['slope_classes']['results']['3'],
      'four': results['slope_classes']['results']['4'],
    },
    'mnt_diff': {
      'is_set': false,
    },
  };

  if ('mnt_diff' in results && results['mnt_diff']['results']['volume'] !== 0) {
    this.data['mnt_diff'] = {
      'is_set': true,
      'unit': results['mnt_diff']['unit'],
      'volume': results['mnt_diff']['results']['volume'],
    };
  } else {
    this.data['mnt_diff']['is_set'] = false;
  }

  if ('statistics' in results['mnt']) {
    this.data['dtm']['stat_ci'] = results['mnt']['statistics']['confidenceInterval'];
    this.data['dtm']['stat_pop'] = results['mnt']['statistics']['population'];
    this.data['dtm']['stat_poi'] = results['mnt']['statistics']['nbPoints'];
    this.data['dtm']['stat_cl'] = results['mnt']['statistics']['confidenceLevel'];
  }
  if ('statistics' in results['slope_classes']) {
    this.data['slope']['stat_ci'] = results['slope_classes']['statistics']['confidenceInterval'];
    this.data['slope']['stat_pop'] = results['slope_classes']['statistics']['population'];
    this.data['slope']['stat_poi'] = results['slope_classes']['statistics']['nbPoints'];
    this.data['slope']['stat_cl'] = results['slope_classes']['statistics']['confidenceLevel'];
  }
};

exports.controller('sitnRasterstatisticsController', exports.Controller);

export default exports;
