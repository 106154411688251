/**
 * @module sitn.feedback.module
 */
import ngeoLocation from 'ngeo/statemanager/Location.js';
import angular from 'angular';

const exports = angular.module('feedback', [ngeoLocation.name]);

exports.Component = {
  controller: 'sitnFeedbackController',
  bindings: {
    show_modal: '=sitnFeedbackShow',
  },
  templateUrl: () => 'sitn/feedback',
};

exports.run(
  /* @ngInject */ ($templateCache) => {
    $templateCache.put('sitn/feedback', require('./feedback.html'));
  }
);

exports.component('sitnFeedback', exports.Component);

/**
 * @param {!angular.Scope} $scope Angular scope.
 * @param {!angular.$http} $http Angular http service.
 * @param {string} sitnFeedbackUrl URL of the SITN feedbackservice webservice.
 * @param {ngeo.statemanager.Location} ngeoLocation ngeo Location service.
 * @constructor
 * @private
 * @ngInject
 */
exports.Controller = function ($scope, $http, sitnFeedbackUrl, ngeoLocation) {
  /**
   * @type {boolean}
   * @private
   */
  this.show_modal;

  /**
   * @type {boolean}
   * @private
   */
  this.show_modal2 = true;

  /**
   * @type {angular.$http}
   * @private
   */
  this.http_ = $http;

  /**
   * @type {string}
   * @private
   */
  this.url_ = sitnFeedbackUrl;

  /**
   * @type {!ngeo.statemanager.Location}
   * @private
   */
  this.ngeoLocation_ = ngeoLocation;

  /**
   * @type {string}
   * @private
   */
  this.permalink;

  /**
   * @type {boolean}
   * @export
   */
  this.feedback_send = false;

  /**
   * @type {string}
   * @export
   */
  this.email;

  /**
   * @type {string}
   * @export
   */
  this.email_optional;

  /**
   * @type {string}
   * @export
   */
  this.feedback_text;

  $scope.$watch(
    // First arg = value to watch
    function () {
      return this.feedback_send;
    }.bind(this),
    function (newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === true) {
          this.feedbackSubmit();
        }
      }
    }.bind(this)
  );
  $scope.$watch(
    // First arg = value to watch
    function () {
      return this.show_modal;
    }.bind(this),
    function (newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === true) {
          this.getPermalink();
        }
      }
    }.bind(this)
  );
};

exports.Controller.prototype.feedbackSubmit = function () {
  if (
    (this.email && this.email.indexOf('@') === -1) ||
    (this.email_optional && this.email_optional.indexOf('@') === -1)
  ) {
    alert("Une adresse email n'est pas valide");
    return;
  }

  if (!this.feedback_text) {
    alert('Veuillez saisir une descritption du problème.');
    return;
  }

  if (this.feedback_text.length > 1000) {
    alert('Votre texte est trop long (max 1000 caractères).');
    return;
  }
  this.show_modal2 = false;
  this.http_
    .post(
      this.url_,
      $.param({
        permalink: this.permalink.toString(),
        ua: navigator.userAgent,
        email: this.email,
        email_optional: this.email_optional,
        feedback: this.feedback_text,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        },
      }
    )
    .then(
      function successCallback() {
        this.show_modal = false;
        this.show_modal2 = true;
        alert(
          [
            'Merci ! Votre demande est bien partie.',
            '\n\n',
            'Suivant votre demande, une personne du SITN ',
            'prendra bientôt contact avec vous.',
          ].join('')
        );
      }.bind(this),
      function errorCallback() {
        this.show_modal = false;
        this.show_modal2 = true;
        alert('Une erreur est survenue. Merci de contacter le SITN (sitn@ne.ch)');
      }.bind(this)
    );
};

exports.Controller.prototype.getPermalink = function () {
  this.permalink = this.ngeoLocation_.getUriString();
};

exports.controller('sitnFeedbackController', exports.Controller);

export default exports;
