/**
 * Application entry point.
 *
 * This file defines the "sitn_desktop" Closure namespace, which is be used as
 * the Closure entry point (see "closure_entry_point" in the "build.json"
 * file).
 *
 * This file includes `import`'s for all the components/directives used
 * by the HTML page and the controller to provide the configuration.
 */

import './sass/desktop.scss';
import './sass/vars_desktop.scss';
import angular from 'angular';
import sitnAbstractdesktopController, {AbstractSITNController} from './abstractsitndesktop.js';
import ngeoStreetviewModule from 'ngeo/streetview/module.js';

/**
 * @private
 */
class Controller extends AbstractSITNController {
  /**
   * @param {angular.IScope} $scope Scope.
   * @param {angular.auto.IInjectorService} $injector Main injector.
   * @ngInject
   * @export
   */
  constructor($scope, $injector) {
    super($scope, $injector);

    this.gettextCatalog.getString('link_di01');
    this.gettextCatalog.getString('servitudes_url');
    this.gettextCatalog.getString('img_link_mutation');

    $scope.$watch(
      () => this.gmfThemeManager.getThemeName(),
      (newTheme) => {
        if (newTheme === 'mobilite') {
          this.showMistraDistancebutton = true;
        } else {
          this.showMistraDistancebutton = false;
        }
      }
    );
  }
}

/**
 * @hidden
 */
const sitnModule = angular.module('Appdesktop', [
  sitnAbstractdesktopController.name,
  ngeoStreetviewModule.name,
]);

sitnModule.value('gmfContextualdatacontentTemplateUrl', 'gmf/contextualdata');
sitnModule.run(
  /**
   * @ngInject
   * @param {angular.ITemplateCacheService} $templateCache
   */
  ($templateCache) => {
    // @ts-ignore: webpack
    $templateCache.put('gmf/contextualdata', require('./contextualdata.html'));
  }
);

sitnModule.controller('DesktopController', Controller);

export default module;
