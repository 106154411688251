/**
 * @module sitn.misc.filters
 */
import angular from 'angular';

const exports = angular.module('sitnAngularFilters', []);

/**
 * @return {function(Array.<number>):number} my description.
 */
exports.CalculateSlopePercents = function () {
  /**
   * @param {number} value A value.
   * @return {number} The value in percent.
   */
  const filterFn = function (value) {
    let slope_value_percents = '';
    if (value !== undefined) {
      slope_value_percents = Math.round(100 * Math.tan((value * Math.PI) / 180));
    }
    return slope_value_percents;
  };
  return filterFn;
};

exports.filter('sitnCalculateSlopePercents', exports.CalculateSlopePercents);

/**
 * @return {ngeox.number} Function used to format number into a string.
 */
exports.RoundNumber = function () {
  /**
   * @param {number} number The number to format.
   * @param {number=} opt_precision The used precision, default is 2.
   * @return {string} The formatted string.
   */
  const filterFn = function (number, opt_precision) {
    if (opt_precision === undefined) {
      opt_precision = 2;
    }
    return Number(number).toFixed(opt_precision);
  };
  return filterFn;
};

exports.filter('sitnRoundNumber', exports.RoundNumber);

/**
 * @param {angular.IFilterService} $filter Angular filter
 * @return {numberCoordinates} A function to format numbers into coordinates string.
 * @ngInject
 * @ngdoc filter
 * @ngname ngeoNumberCoordinates
 */
function NumberCoordinatesFilter($filter) {
  /**
   * @param {import("ol/coordinate.js").Coordinate} coordinates Array of two numbers.
   * @param {(number|string)=} opt_fractionDigits Optional number of digit.
   *     Default to 0.
   * @param {string=} opt_template Optional template. Default to '{x} {y}'.
   *     Where "{x}" will be replaced by the easting coordinate and "{y}" by the
   *     northing one. Note: Use a html entity to use the semicolon symbol
   *     into a template.
   * @return {string} Number formatted coordinates.
   */
  const filterFn = function (coordinates, opt_fractionDigits, opt_template) {
    const template = opt_template ? opt_template : '{x} {y}';
    const x = coordinates[0];
    const y = coordinates[1];
    const fractionDigits = parseInt(/** @type {string} */ (opt_fractionDigits), 10) | 0;
    let x_str = $filter('number')(x, fractionDigits);
    let y_str = $filter('number')(y, fractionDigits);
    x_str = x_str.replace(/'/g, '');
    y_str = y_str.replace(/'/g, '');

    return template.replace('{x}', x_str).replace('{y}', y_str);
  };
  return filterFn;
}

exports.filter('sitnNumberCoordinates', NumberCoordinatesFilter);

export default exports;
