/**
 * Application entry point.
 *
 * This file defines the "sitn_desktop" Closure namespace, which is be used as
 * the Closure entry point (see "closure_entry_point" in the "build.json"
 * file).
 *
 * This file includes `import`'s for all the components/directives used
 * by the HTML page and the controller to provide the configuration.
 */

import angular from 'angular';
import {isEventUsingCtrlKey} from 'ngeo/utils';
import gmfControllersAbstractDesktopController, {
  AbstractDesktopController,
} from 'gmf/controllers/AbstractDesktopController';
import sitnBase from '../index';
import sitnAddressModule from 'sitn/address/module.js';
import sitnRasterstatisticsModule from 'sitn/rasterstatistics/module.js';
import sitnMistradistanceModule from 'sitn/mistradistance/module.js';
import sitnRedirectbuttonModule from 'sitn/redirectbutton/module.js';
import sitnExternalentryModule from 'sitn/externalentry/module.js';
import sitnFeedbackModule from 'sitn/feedback/module.js';
import sitnMiscFilters from 'sitn/misc/filters.js';
import sitnWastewaterFlowModule from 'sitn/wastewaterflow/module.js';
import gmfImportModule from 'gmf/import/module.js';
import ngeoMessagePopup from 'ngeo/message/Popup.js';
import ngeoMiscToolActivate from 'ngeo/misc/ToolActivate';
import panels from 'gmfapi/store/panels';

/**
 * @private
 */
export class AbstractSITNController extends AbstractDesktopController {
  /**
   * @param {angular.IScope} $scope Scope.
   * @param {angular.auto.IInjectorService} $injector Main injector.
   * @ngInject
   */
  constructor($scope, $injector) {
    super($scope, $injector);

    // Allow angular-gettext-tools to collect the strings to translate
    // something like hidden attributes in Mapfiles
    /** @type {angular.gettext.gettextCatalog} */
    this.gettextCatalog = $injector.get('gettextCatalog');

    const $timeout = $injector.get('$timeout');

    /**
     * @type {gmf.theme.Manager}
     * @private
     */
    this.gmfThemeManager_ = $injector.has('gmfThemeManager') ? $injector.get('gmfThemeManager') : undefined;

    /**
     * @type {gmfx.User}
     * @private
     */
    this.gmfUser_ = $injector.has('gmfUser') ? $injector.get('gmfUser') : undefined;

    /**
     * The ngeo ToolActivate manager service.
     * @type {import("ngeo/misc/ToolActivateMgr.js").ToolActivateMgr}
     */
    this.ngeoToolActivateMgr = $injector.get('ngeoToolActivateMgr');

    /**
     * @type {boolean}
     */
    this.drawLidarprofilePanelActive = false;

    /**
     * @type {boolean}
     */
    this.lidarProfileFooterActive = true;

    // Open the 'web-component' lidar panel
    $scope.$watch(
      () => this.drawLidarprofilePanelActive,
      (newVal) => {
        if (newVal) {
          panels.openToolPanel('lidar');
        } else {
          panels.closeToolPanel();
        }
      }
    );

    // Make visible the footer
    panels.getActiveFooterPanel().subscribe({
      next: (panel) => {
        this.lidarProfileFooterActive = panel === 'lidar';
        $timeout(() => {}); // this triggered on DOM click, we call $timeout to force Angular diggest
      },
    });

    const drawLidarprofilePanelActive = new ngeoMiscToolActivate(this, 'drawLidarprofilePanelActive');
    this.ngeoToolActivateMgr.registerTool('mapTools', drawLidarprofilePanelActive, false);

    /**
     * @type {string}
     */
    this.sitnModalUrl;

    /**
     * @type {number}
     */
    this.sitnModalWidth;

    /**
     * @type {number}
     */
    this.sitnModalHeight;

    /**
     * @type {boolean}
     */
    this.sitnModalIsOpen = false;

    /**
     * @type {string}
     */
    this.sitnModalTitle;

    /**
     * @type {boolean}
     */
    this.showRasterStatbutton = false;

    /**
     * @type {boolean}
     */
    this.showNeophytebutton = false;

    /**
     * @type {boolean}
     */
    this.showMistraDistancebutton = false;

    /**
     * @type {boolean}
     */
    this.showWastewaterFlowbutton = false;

    /**
     * @type {Object}
     */
    this.panelData = {};

    /**
     * @type {angular.$locale} $locale Angular locale
     * @private
     */
    const $locale_ = $injector.get('$locale');
    // Set manually locale number format.
    $scope.$on('$localeChangeSuccess', () => {
      $locale_.NUMBER_FORMATS.DECIMAL_SEP = '.';
      $locale_.NUMBER_FORMATS.GROUP_SEP = "'";
    });

    $scope.$watch(
      () => this.gmfThemeManager_.getThemeName(),
      (newTheme) => {
        if (newTheme === 'agriculture') {
          this.showRasterStatbutton = true;
        } else {
          this.showRasterStatbutton = false;
        }
        if (newTheme === 'cadastre_souterrain') {
          this.showWastewaterFlowbutton = true;
        } else {
          this.showWastewaterFlowbutton = false;
        }
        if (newTheme === 'neophytes') {
          this.showNeophytebutton = true;
        } else {
          this.showNeophytebutton = false;
        }
        if (newTheme === 'routes') {
          this.showMistraDistancebutton = true;
        } else {
          this.showMistraDistancebutton = false;
        }
      }
    );

    window['sitnExterns'] = {};

    // Bindings to sitnExterns
    window['sitnExterns']['openWindow'] = this.sitnDisplayWindow.bind(this);
    window['sitnExterns']['openPanel'] = this.sitnOpenPanel.bind(this);
    window['sitnExterns']['openTab'] = this.sitnOpenTab.bind(this);
    window['sitnExterns']['openUrl'] = this.sitnOpenUrl.bind(this);

    this.sitnHelpUrl = $injector.get('sitnHelpUrl');
    this.sitnAdresseHelpUrl = $injector.get('sitnAdresseHelpUrl');
    this.sitnNeophyteAnnounceUrl = $injector.get('sitnNeophyteAnnounceUrl');
    this.sitnNeophyteUrl = $injector.get('sitnNeophyteUrl');
    this.sitnWastewaterFlowUrl = $injector.get('sitnWastewaterFlowUrl');

    window['sitnExterns']['sitn_defense_incendie_url'] = $injector.get('sitnDefenseIncendieUrl');
    window['sitnExterns']['sitn_document_url'] = $injector.get('sitnDocumentUrl');
    window['sitnExterns']['sitn_cadsout_url'] = $injector.get('sitnCadsoutUrl');
    window['sitnExterns']['sitn_energy_url'] = $injector.get('sitnEnergyUrl');
    window['sitnExterns']['sitn_forets_arbres_promouvoir_url'] = $injector.get(
      'sitnForetsArbresPromouvoirUrl'
    );
    window['sitnExterns']['sitn_hc_home_url'] = $injector.get('sitnHcHomeUrl');
    window['sitnExterns']['sitn_hc_mutation_list_url'] = $injector.get('sitnHcMutationListUrl');
    window['sitnExterns']['sitn_ilots_chaleur_url'] = $injector.get('sitnIlotsChaleurUrl');
    window['sitnExterns']['sitn_insar_url'] = $injector.get('sitnInsarUrl');
    window['sitnExterns']['sitn_lubis_url'] = $injector.get('sitnLubisUrl');
    window['sitnExterns']['sitn_mensuration_url'] = $injector.get('sitnMensurationUrl');
    window['sitnExterns']['sitn_meteo_url'] = $injector.get('sitnMeteoUrl');
    window['sitnExterns']['sitn_neophyte_url'] = $injector.get('sitnNeophyteUrl');
    window['sitnExterns']['sitn_orthophoto_url'] = $injector.get('sitnOrthophotoUrl');
    window['sitnExterns']['sitn_owner_url'] = $injector.get('sitnOwnerUrl');
    window['sitnExterns']['sitn_patri_url'] = $injector.get('sitnPatrimoineUrl');
    window['sitnExterns']['sitn_ppe_url'] = $injector.get('sitnPpeUrl');
    window['sitnExterns']['sitn_police_document_url'] = $injector.get('sitnPoliceDocumentUrl');
    window['sitnExterns']['sitn_police_doc_url'] = $injector.get('sitnPoliceDocUrl');
    window['sitnExterns']['sitn_pollution_certificate_url'] = $injector.get('sitnPollutionCertificateUrl');
    window['sitnExterns']['sitn_roadview_base_url'] = $injector.get('sitnRoadviewBaseUrl');
    window['sitnExterns']['sitn_routes_documents_url'] = $injector.get('sitnRoutesDocumentsUrl');
    window['sitnExterns']['sitn_satac_document_url'] = $injector.get('sitnSatacDocumentUrl');
    window['sitnExterns']['sitn_satac_dossiers_url'] = $injector.get('sitnSatacDossiersUrl');
    window['sitnExterns']['sitn_satacv1_complement_url'] = $injector.get('sitnSatacV1ComplementUrl');
    window['sitnExterns']['sitn_servitudes_url'] = $injector.get('sitnServitudesUrl');
    window['sitnExterns']['sitn_sau_list_url'] = $injector.get('sitnSauUrl');
    window['sitnExterns']['sitn_stand_tir_url'] = $injector.get('sitnStandTirUrl');
    window['sitnExterns']['sitn_ouvrage_protection_url'] = $injector.get('sitnOuvrageProtectionUrl');
    window['sitnExterns']['sitn_vtt_index_url'] = $injector.get('sitnVttIndexUrl');
    window['sitnExterns']['sitn_vdn_publicite_url'] = $injector.get('sitnVdnPubliciteUrl');
    window['sitnExterns']['sitn_vtt_edit_url'] = $injector.get('sitnVttEditUrl');
  }

  /**
   * @param {JQuery.Event} event keydown event.
   */
  onKeydown(event) {
    if (event && isEventUsingCtrlKey(event) && event.key === 'p') {
      this.printPanelActive = true;
      event.preventDefault();
    }
  }

  /**
   * @param {string} title Title.
   * @param {string} url Url.
   * @param {number} width Width.
   * @param {number} height Height.
   */
  sitnDisplayWindow(title, url, width, height) {
    this.sitnModalUrl = url;
    this.sitnModalWidth = width;
    this.sitnModalHeight = height;
    this.sitnModalTitle = title;
    this.sitnModalIsOpen = true;
    this.$scope.$apply();
  }

  /**
   * @param {string} moduleName The name of the target module.
   * @param {string} data Data to bind to module.
   */
  sitnOpenPanel(moduleName, data) {
    this.panelData[moduleName] = data;
    this[`${moduleName}PanelActive`] = true;
    this.$scope.$apply();
  }

  /**
   * @param {string} base_url url
   * @param {string} param_string params
   */
  sitnOpenTab(base_url, param_string) {
    let url;

    if (base_url in window['sitnExterns']) {
      url = window['sitnExterns'][base_url];
    } else {
      url = base_url;
    }
    if (param_string && param_string !== '') {
      url += '?' + param_string;
    }
    const win = window.open(url, '_blank');
    if (win) {
      win.focus();
    } else {
      alert("Merci d'autoriser l'ouverture de fenêtres supplémentaires");
    }
  }

  /**
   * @param {string} base_url url
   * @param {string} suffix params
   * @param {string} param_string params
   */
  sitnOpenUrl(base_url, suffix, param_string) {
    let url;

    if (base_url in window['sitnExterns']) {
      url = window['sitnExterns'][base_url];
    } else {
      url = base_url;
    }
    if (suffix) {
      url += '/' + suffix;
    }
    if (param_string) {
      url += '?' + param_string;
    }
    const win = window.open(url, '_blank');
    if (win) {
      win.focus();
    } else {
      alert("Merci d'autoriser l'ouverture de fenêtres supplémentaires");
    }
  }
}

/**
 * @hidden
 */
const sitnModule = angular.module('AppAbstractDesktop', [
  sitnBase.module.name,
  gmfControllersAbstractDesktopController.name,
  gmfImportModule.name,
  sitnAddressModule.name,
  sitnRasterstatisticsModule.name,
  sitnMistradistanceModule.name,
  sitnRedirectbuttonModule.name,
  sitnExternalentryModule.name,
  sitnFeedbackModule.name,
  sitnMiscFilters.name,
  sitnWastewaterFlowModule.name,
  ngeoMessagePopup.name,
]);

export default sitnModule;
