/**
 * @module sitn.externalentry.module
 */
import olFormatGeoJSON from 'ol/format/GeoJSON.js';
import olVectorLayer from 'ol/layer/Vector.js';
import olVectorSource from 'ol/source/Vector.js';
import olStyleCircle from 'ol/style/Circle.js';
import olStyleFill from 'ol/style/Fill.js';
import olStyleStroke from 'ol/style/Stroke.js';
import olStyleStyle from 'ol/style/Style.js';
import olCollection from 'ol/Collection.js';
import angular from 'angular';
import ngeoMapFeatureOverlayMgr from 'ngeo/map/FeatureOverlayMgr';

const exports = angular.module('externalentry', []);

exports.Component = {
  controller: 'sitnExternalEntryController',
  bindings: {
    'map': '<sitnExternalEntryMap',
  },
  templateUrl: () => 'sitn/externalentry',
};

exports.run(
  /* @ngInject */ ($templateCache) => {
    $templateCache.put('sitn/externalentry', require('./externalentry.html'));
  }
);

exports.component('sitnExternalEntry', exports.Component);

/**
 * @param {!angular.$http} $http Angular http service.
 * @constructor
 * @private
 * @ngInject
 */
exports.Controller = function ($http) {
  /**
   * @type {boolean}
   * @export
   */
  this.show = false;

  /**
   * @type {String}
   * @private
   */
  this.tooltiptitle;

  /**
   * @type {String}
   * @private
   */
  this.tooltipmessage;

  /**
   * @type {angular.$http}
   * @private
   */
  this.http_ = $http;

  /**
   * A layertree containing additional layers
   * @type {Object}
   * @private
   */
  this.layertree = {};

  /**
   * @type {ol.Map}
   * @private
   */
  this.map;

  /**
   * @type {import('ngeo/map/FeatureOverlay').FeatureOverlay}
   */
  this.drawFeatureLayer = ngeoMapFeatureOverlayMgr.getFeatureOverlay();

  /**
   * Collection of features for the draw interaction
   *
   * @type {import('ol/Collection').default<import('ol/Feature').default<import('ol/geom/Geometry').default>>}
   */
  this.collection_ = new olCollection();

  // default styles
  const customFill = new olStyleFill({color: [255, 255, 255, 0.4]});
  const customStroke = new olStyleStroke({color: [170, 0, 0, 1], width: 2});
  const customMarker = new olStyleCircle({
    radius: 7,
    fill: customFill,
    stroke: customStroke,
  });

  /**
   * FeatureStyle used by the displayquerywindow directive
   * @type {ol.style.Style}
   * @private
   */
  this.customFeatureStyle = new olStyleStyle({
    image: customMarker,
    fill: customFill,
    stroke: customStroke,
  });
};

exports.Controller.prototype.$onInit = function () {
  window['sitnExterns']['callExternalEntry'] = this.callExternalEntry.bind(this);
  this.drawFeatureLayer.setStyle(this.customFeatureStyle);
  this.drawFeatureLayer.setFeatures(this.collection_);
};

/**
 * Adds features to map based on an url.
 * The features can be added as an overlay or a layertree:
 *  - overlay: the features will be overwritten every time the plugin is called
 *  - layertree: the features can be kept and styled if options are provided
 * To see an example of layertree mode, check hydrogeol plugin.
 * @param {String} url The url providing the data
 * @param {Object} url_params Additional parameters to add to the url
 * @param {String} tooltiptitle Title of tooltip (delete button on map)
 * @param {String} tooltipmessage Content of tooltip
 * @param {Object} options Options currently supported:
 *  keep : an array of layernames to keep
 *  layername: string of layername
 *  color_attribute: the name of the attribute on which we want to set a color mapping
 *  color_mapping: an object describing how to colorize based on color_attribute values. Example:
 *    color_mapping: {
 *      'bad': [170, 0, 0, 1],
 *      'good': [60, 160, 0, 1]
 *    }
 * @export
 */
exports.Controller.prototype.callExternalEntry = function (
  url,
  url_params,
  tooltiptitle,
  tooltipmessage,
  options
) {
  this.tooltiptitle = tooltiptitle;
  this.tooltipmessage = tooltipmessage;
  this.http_.get(url, {params: url_params}).then(
    (response) => {
      this.addFeatures(response.data, options);
    },
    () => alert('Une erreur est survenue. Merci de contacter le SITN (sitn@ne.ch)')
  );
};

/**
 * Manages the features to be shown on map
 * @param {Object} data Data received from the webservice
 * @private
 */
exports.Controller.prototype.addFeatures = function (data, options = {}) {
  // start by removing everything from map
  this.removeFeatures();

  // keep in the layertree only the layers provided in options.keep
  if (options['keep']) {
    Object.keys(this.layertree).forEach((layer) => {
      if (!options['keep'].includes(layer)) {
        delete this.layertree[layer];
      }
    });
  } else {
    this.layertree = {};
  }

  // parse the geojson
  let features = [];
  if (data['type'] === 'FeatureCollection') {
    features = new olFormatGeoJSON().readFeatures(data);
  } else {
    features[0] = new olFormatGeoJSON().readFeature(data);
  }

  // Style features individually based on a color mapping of feature properties
  if (options['color_attribute'] && options['color_mapping']) {
    features.forEach((feature) => {
      const value = feature.get(options['color_attribute']);
      const color = options['color_mapping'][value];
      const customFill = new olStyleFill({color: color});
      const customStroke = new olStyleStroke({color: color, width: 2});
      const customMarker = new olStyleCircle({
        radius: 7,
        fill: customFill,
        stroke: customStroke,
      });
      feature.setStyle(
        new olStyleStyle({
          image: customMarker,
          fill: customFill,
          stroke: customStroke,
        })
      );
    });
  }

  // adds layer to layertree if it has a name
  if (options['layername']) {
    this.addLayer(options['layername'], features);
    this.addLayerTree();
  } else {
    features.forEach(function (feature) {
      this.collection_.push(feature);
    }, this);
  }
  this.show = true;
};

/**
 * Removes features from overlay and all added layers
 * @export
 */
exports.Controller.prototype.removeFeatures = function () {
  this.show = false;
  this.collection_.clear();
  Object.keys(this.layertree).forEach((layer) => {
    this.map.removeLayer(this.layertree[layer]);
  });
};

/**
 * Creates a layer in the layertree
 * @private
 */
exports.Controller.prototype.addLayer = function (layername, features) {
  this.layertree[layername] = new olVectorLayer({
    source: new olVectorSource({
      features: features,
    }),
    style: this.customFeatureStyle,
  });
};

/**
 * Removes a layer in the layertree
 * @private
 */
exports.Controller.prototype.removeLayer = function (layername) {
  delete this.layertree[layername];
};

/**
 * Adds layertree to map
 * @private
 */
exports.Controller.prototype.addLayerTree = function () {
  Object.keys(this.layertree).forEach((layer) => {
    this.map.addLayer(this.layertree[layer]);
  });
};

exports.controller('sitnExternalEntryController', exports.Controller);

export default exports;
