/**
 * @module sitn.redirectbutton.module
 */
import angular from 'angular';

const exports = angular.module('redirectbutton', []);

exports.Component = {
  controller: 'sitnRedirectbuttonController',
  bindings: {
    'map': '<sitnRedirectbuttonMap',
    'destination': '<sitnRedirectbuttonDestination',
    'destination_params': '<sitnRedirectbuttonDestinationParameters',
    'title': '<sitnRedirectbuttonTitle',
    'icon': '<sitnRedirectbuttonIcon',
  },
  templateUrl: () => 'sitn/redirectbutton',
};

exports.run(
  /* @ngInject */ ($templateCache) => {
    $templateCache.put('sitn/redirectbutton', require('./redirectbutton.html'));
  }
);

exports.component('sitnRedirectbutton', exports.Component);

/**s
 * @param {!angular.$window} $window Angular window service.
 * @constructor
 * @private
 * @ngInject
 */
exports.Controller = function ($window) {
  /**
   * @type {string}
   * @export
   */
  this.destination;

  /**
   * @type {string}
   * @export
   */
  this.destination_params;

  /**
   * @type {string}
   */
  this.title;

  /**
   * @type {string}
   */
  this.icon;

  /**
   * @type {ol.Map}
   * @export
   */
  this.map;

  /**
   * @type {angular.$window}
   * @export
   */
  this.$window_ = $window;
};

/**
 * @export
 */
exports.Controller.prototype.redirectTo = function () {
  var url = this.destination;
  let params_str = '';
  if (this.destination_params) {
    params_str += '?';
    if (this.destination_params === 'map_params') {
      params_str += this.getMapParams();
    }
  }
  url += params_str;
  this.$window_.open(url, '_blank');
};

exports.Controller.prototype.getMapParams = function () {
  const view = this.map.getView();
  const center = view.getCenter();
  const zoom = view.getZoom();
  return ['map_zoom=', zoom, '&map_x=', center[0], '&map_y=', center[1]].join('');
};

exports.controller('sitnRedirectbuttonController', exports.Controller);

export default exports;
