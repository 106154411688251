/**
 * @module sitn.wastewaterflow.module
 */
import olFormatGeoJSON from 'ol/format/GeoJSON.js';
import olSourceVector from 'ol/source/Vector.js';
import olLayerVector from 'ol/layer/Vector.js';
import ngeoToolActivate from 'ngeo/misc/ToolActivate.js';
import ngeoToolActivateMgr from 'ngeo/misc/ToolActivateMgr.js';
import * as olEvents from 'ol/events.js';
import {unByKey} from 'ol/Observable.js';
import olStyleCircle from 'ol/style/Circle.js';
import olStyleStyle from 'ol/style/Style.js';
import olStyleFill from 'ol/style/Fill.js';
import olStyleStroke from 'ol/style/Stroke.js';
import angular from 'angular';

const exports = angular.module('wastewaterflow', [ngeoToolActivateMgr.name]);

exports.Component = {
  controller: 'sitnWastewaterFlowController',
  bindings: {
    'map': '<', // one way Angular binding !
    'active': '=',
    'layers': '<',
  },
  templateUrl: () => 'sitn/wastewaterflow',
};

exports.run(
  /* @ngInject */ ($templateCache) => {
    $templateCache.put('sitn/wastewaterflow', require('./wastewaterflow.html'));
  }
);

exports.component('sitnWastewaterflow', exports.Component);

/**
 * @param {!angular.Scope} $scope Angular scope.
 * @param {!angular.$http} $http Angular http service.
 * @param {gmf.DataSourcesHelper} gmfDataSourcesHelper Ngeo ToolActivate service.
 * @param {ngeo.misc.ToolActivateMgr} ngeoToolActivateMgr Ngeo ToolActivate manager
 *     service.
 * @param {string} sitnWastewaterFlowUrl URL of the SITN mistra service.
 * @constructor
 * @private
 * @ngInject
 */
exports.Controller = function (
  $scope,
  $http,
  gmfDataSourcesHelper,
  ngeoToolActivateMgr,
  sitnWastewaterFlowUrl
) {
  /**
   * @type {angular.$http}
   * @private
   */
  this.$http_ = $http;

  /**
   * @type {Array<string>}
   */
  this.layers;

  /**
   * @type {boolean}
   * @export
   */
  this.hidetext = true;

  /**
   * @type {boolean}
   * @export
   */
  this.showcog = false;

  /**
   * @type {number}
   * @export
   */
  this.precision = 0.01;

  /**
   * @type {number}
   */
  this.nb_iterations = 100;

  /**
   * @type {boolean}
   * @export
   */
  this.showwarning = false;

  /**
   * @type {ol.Map}
   * @export
   */
  this.map;
  /**
   * @type {boolean}
   */
  this.active;

  /**
   * @type {string}
   * @private
   */
  this.streamtype = 'downstream';

  /**
   * @type {string}
   * @private
   */
  this.sitnWastewaterFlowUrl_ = sitnWastewaterFlowUrl;

  /**
   * @type {ngeo.misc.ToolActivateMgr}
   * @private
   */
  this.ngeoToolActivateMgr_ = ngeoToolActivateMgr;

  /**
   * @type {ol.source.Vector}
   */
  this.source = new olSourceVector();

  /**
   * @type {ol.EventsKey}
   * @private
   */
  this.wasterwaterClickListenerKey_;

  /**
   * @type {ngeo.DataSourcesHelper}
   * @private
   */
  this.gmfDataSourcesHelper_ = gmfDataSourcesHelper;

  // Initialize the tools inside of the tool manager
  this.tool = new ngeoToolActivate(this, 'active');
  this.ngeoToolActivateMgr_.registerTool('mapTools', this.tool, false);

  $scope.$watch(
    // First arg = value to watch
    function () {
      return this.active;
    }.bind(this),
    function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateEventsListening_(newValue);
      }
    }.bind(this)
  );
};

exports.Controller.prototype.$onInit = function () {
  var styleFunction = (feature) => {
    const geom_type = feature.getGeometry().getType();

    if (geom_type == 'Point') {
      return new olStyleStyle({
        image: new olStyleCircle({
          radius: 8,
          fill: new olStyleFill({
            color: [0, 255, 0, 0.4],
          }),
          stroke: new olStyleStroke({
            color: [0, 255, 0, 0.6],
            width: 1,
          }),
        }),
      });
    } else if (geom_type == 'LineString') {
      return new olStyleStyle({
        stroke: new olStyleStroke({
          color: [0, 255, 0, 0.6],
          width: 5,
        }),
      });
    }
  };

  const vectorLayer = new olLayerVector({
    source: this.source,
    style: styleFunction,
  });
  vectorLayer.setMap(this.map);
};
/**
 * @param {boolean} activate Activation state of the plugin
 * @private
 */
exports.Controller.prototype.updateEventsListening_ = function (activate) {
  if (activate === true) {
    this.ngeoToolActivateMgr_.activateTool(this.tool);
    this.mistraClickListenerKey_ = olEvents.listen(this.map, 'click', this.click, this);
  } else {
    this.ngeoToolActivateMgr_.deactivateTool(this.tool);
    unByKey(this.mistraClickListenerKey_);
    this.source.clear();
    this.hidetext = true;
    this.showcog = false;
    this.showwaring = false;
  }
};

/**
 * click action should trigger a HTTP request on coordinates and open a popup.
 * @param {ol.MapBrowserPointerEvent} e An ol map browser pointer event.
 * @private
 */
exports.Controller.prototype.click = function (e) {
  // Check that layer is on !
  let ok = false;
  for (let i = 0; i < this.layers.length; i++) {
    const datasources = this.gmfDataSourcesHelper_.collection;
    const data_array = datasources.getArray();
    let lyr;
    for (let i = 0; i < data_array.length; i++) {
      lyr = data_array[i];
      if (lyr.ogcType === 'WMS' && lyr.visible === true) {
        ok = true;
        break;
      }
    }
    if (ok === true) {
      break;
    }
  }
  if (ok === false) {
    this.active = false;
    alert(
      'Il faut au moins une couche active (canalisations (assainissement) par exemple) qui puisse utiliser cette fonctionnalité.'
    );
    return;
  }

  const est = e.coordinate[0];
  const nord = e.coordinate[1];

  // Empty source
  this.source.clear();
  this.showwarning = false;
  this.showcog = true;
  this.hidetext = false;

  this.$http_
    .get(this.sitnWastewaterFlowUrl_, {
      params: {
        'lon': est,
        'lat': nord,
        'layers': this.layers.join(','),
        'streamtype': this.streamtype,
        'precision': this.precision,
        'limit': this.nb_iterations,
      },
    })
    .then(
      (response) => {
        this.updateMap_(response.data, [est, nord]);
      },
      () => {
        this.showcog = false;
        alert('Une erreur est survenue. Merci de contacter le SITN (sitn@ne.ch)');
      }
    );
};

/**
 * @param {Object} results Returned object from the webservice
 * @param {Array<number>} coordinates Coordinates of clicked point
 * @private
 */
exports.Controller.prototype.updateMap_ = function (results) {
  if (results['distance_error']) {
    this.hidetext = true;
    this.showcog = false;
    alert("Erreur\n\nVeuillez cliquer plus près d'une conduite !");
    return;
  }

  const features = new olFormatGeoJSON().readFeatures(results);
  this.source.addFeatures(features);

  const feature_0 = features[0];
  if (feature_0.getProperties().limit === true) {
    this.showwarning = true;
  }

  this.showcog = false;
};

exports.controller('sitnWastewaterFlowController', exports.Controller);

export default exports;
