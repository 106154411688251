/**
 * This file provides the "sitn" namespace, which is the
 * application's main namespace. And it defines the application's Angular
 * module.
 */

/**
 * @module sitn
 */
const exports = {};

import {decodeQueryString} from 'ngeo/utils.js';
import angular from 'angular';

/**
 * @type {!angular.Module}
 */
exports.module = angular.module('sitn', []);

exports.module.config([
  '$compileProvider',
  function ($compileProvider) {
    if (!('debug' in decodeQueryString(window.location.search))) {
      // Disable the debug info
      $compileProvider.debugInfoEnabled(false);
    }
    $compileProvider.aHrefSanitizationWhitelist(
      /^\s*(https?:|javascript:sitnExterns.open(Window|Tab)|javascript:sitnExterns.copy2Clipboard|mailto:.+@ne.ch)/
    );
  },
]);

export default exports;
